table.mitglieder {
  thead th:first-child,
  tr td:first-child {
    padding-left: 1px;
  }
  thead th:last-child,
  tr td:last-child {
    padding-right: 1px;
  }
  tbody > tr:nth-child(1):hover {
    background: #fff !important;
  }
}