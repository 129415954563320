@use '../../../node_modules/bulma/sass/utilities/initial-variables.scss' as *;
@use '../../../node_modules/bulma/sass/utilities/mixins.scss' as *;

.labeled-input-row,
.form-group.labeled-input-row {
  align-items: center;
  box-sizing: border-box;
  color: $cyan;
  display: flex;
  height: 34px;
  margin-bottom: 30px;
  position: relative;
  text-size-adjust: 100%;

  label {
    align-items: center;
    align-self: stretch;
    border-bottom-color: $grey;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    box-sizing: border-box;
    color: $cyan;
    cursor: default;
    display: flex;
    flex-wrap: nowrap;
    height: 34px;
    line-height: 21.3333px;
    margin-bottom: 0px;
    max-width: 100%;
    text-size-adjust: 100%;
    user-select: none;
    white-space: nowrap;
  }
  
  input {
    align-items: center;
    align-self: stretch;
    border-bottom-color: $grey;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-style: none;
    border-right-style: none;
    border-top-style: none;
    box-shadow: none;
    box-sizing: border-box;
    color: $grey;
    cursor: text;
    display: flex;
    flex-grow: 1;
    height: 34px;
    letter-spacing: normal;
    line-height: 21.3333px;
    margin: 0;
    min-width: 0px;
    outline-style: none;
    padding-bottom: 6px;
    padding-left: 15px;
    padding-right: 12px;
    padding-top: 6px;
    text-align: start;
    text-indent: 0px;
    text-rendering: auto;
    text-shadow: none;
    text-size-adjust: 100%;
    text-transform: none;
    word-spacing: 0px;
    writing-mode: horizontal-tb;
  }
}

.labeled-textarea-row,
.form-group.labeled-textarea-row {
  align-items: center;
  box-sizing: border-box;
  color: $cyan;
  display: block;
  height: auto;
  margin-bottom: 30px;
  position: relative;
  text-size-adjust: 100%;
  label {
    align-items: center;
    align-self: stretch;
    border-bottom-color: $grey;
    border-bottom-style: none;
    border-bottom-width: 1px;
    box-sizing: border-box;
    color: $cyan;
    cursor: default;
    display: flex;
    flex-wrap: nowrap;
    height: 34px;
    line-height: 21.3333px;
    margin-bottom: 0px;
    max-width: 100%;
    text-size-adjust: 100%;
    user-select: none;
    white-space: nowrap;
  }
  textarea {
    background-attachment: local;
    background-clip: border-box;
    background-color: rgba(0, 0, 0, 0);
    background-image: linear-gradient($grey 1px, transparent 1px);
    background-origin: padding-box;
    background-position-x: 0px;
    background-position-y: 0px;
    background-size: 100% 41px;
    border: none;
    box-shadow: none;
    box-sizing: border-box;
    color: $grey;
    cursor: text;
    display: block;
    flex-direction: column;
    // height: 174px;
    letter-spacing: normal;
    line-height: 41px;
    margin: 0;
    outline-style: none;
    overflow-wrap: break-word;
    overflow-x: auto;
    overflow-y: auto;
    padding: 10px 0 0 0;
    resize: vertical;
    text-align: start;
    text-indent: 0px;
    text-rendering: auto;
    text-shadow: none;
    text-size-adjust: 100%;
    text-transform: none;
    white-space: pre-wrap;
    width: 100%;
    word-spacing: 0px;
    writing-mode: horizontal-tb;
  }
}
.checkbox,
.form-group.checkbox {
  input {
    background-color: rgba(0, 0, 0, 0);
    border-image-outset: 0px;
    border-image-repeat: stretch;
    border-image-slice: 100%;
    border-image-source: none;
    border-image-width: 1;
    border: 1px solid $grey;
    box-sizing: border-box;
    color: $grey;
    content: "";
    cursor: pointer;
    display: inline-block;
    height: 16px;
    letter-spacing: normal;
    line-height: 16px;
    margin: 0 6px 5px 0;
    outline-color: $grey;
    outline-style: none;
    outline-width: 0px;
    padding: 0px;
    position: relative;
    text-align: start;
    text-indent: 0px;
    text-rendering: auto;
    text-shadow: none;
    text-size-adjust: 100%;
    text-transform: none;
    top: 1px;
    vertical-align: middle;
    width: 16px;
    word-spacing: 0px;
    writing-mode: horizontal-tb;
    -webkit-appearance: none;
    -webkit-rtl-ordering: logical;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-border-image: none;
  }
}

html input:-webkit-autofill {
  box-shadow: 0 0 0 1000px #fff inset
}

input[type=checkbox],input[type=radio] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  display: inline-block;
  content: '';
  width: 16px;
  height: 16px;
  margin: 0;
  border: 1px solid #eee;
  margin-right: 6px;
  margin-bottom: 5px;
  cursor: pointer;
  vertical-align: middle;
  top: 1px;
  line-height: 1;
  outline: 0
}
input[type=checkbox]:before,input[type=radio]:before {
  display: block;
  position: absolute;
  content: '';
  left: 3px;
  top: 3px;
  right: 3px;
  bottom: 3px;
  background-color: $cyan;
  -webkit-transition: all 150ms cubic-bezier(.445,.05,.55,.95);
  transition: all 150ms cubic-bezier(.445,.05,.55,.95);
  opacity: 0;
  -webkit-transform: scale(.9);
  transform: scale(.9)
}
input[type=checkbox]:checked:before,input[type=radio]:checked:before {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1
}
input[type=checkbox]:active,input[type=checkbox]:focus,input[type=radio]:active,input[type=radio]:focus {
  outline: 0
}
input[type=checkbox][type=radio],input[type=radio][type=radio] {
  border-radius: 50%
}
input[type=checkbox][type=radio]:before,input[type=radio][type=radio]:before {
  border-radius: 50%
}
input[type=checkbox]+label[for],input[type=radio]+label[for] {
  cursor: pointer
}
.checkbox input[type=checkbox],.checkbox input[type=radio] {
  position: relative;
  margin-left: 0
}