@use '../../../node_modules/bulma/sass/utilities/initial-variables.scss' as *;
@use '../../../node_modules/bulma/sass/utilities/mixins.scss' as *;

// Tabs Huetten

#tabs.tabs {
  ul {
    li a {
      color: $grey-dark;
      background-color: $white;
      border: none;
      border-bottom: 1px solid transparent;
      padding: {
        left: 0;
        right: 0;
        bottom: 0;
      }
      font: {
        family: 'Raleway SemiBold';
        size: $size-7;
        weight: 700;
      }
    }
    li a:first-child {
      margin-right: 0.5rem;
    }
    li a:last-child {
      margin-left: 0.5rem;    
    }
    li a:hover {
      color: $cyan;
    }
    li.is-active a {
      color: $cyan;
      background-color: $white;
      border: none;
      border-bottom: 1px solid $cyan;
    }
  }
}

// Big Calendar

.rbc-calendar { 
  height: 1200px;
  // flex-grow: 1;
  // height: 0;
}

.rbc-toolbar-label {
  // visibility: hidden;
}
// .rbc-month-view {
// }

.rbc-btn-group {
  // display: none;
}

.rbc-header {
  text-transform: uppercase;
  text-align: left;
  padding: 6px 0;
  padding-left: 10px;
  font: {
    family: 'Raleway Medium';
    size: $size-7;
    weight: 500;
  }
}

.rbc-event {
  background-color: $cyan;
  border-radius: 0px;
  color: $white;
  padding: {
    top: 2px;
    right: 10px;
    bottom: 2px;
    left: 10px;
  }
  cursor: pointer;
  font: {
    family: 'Raleway Medium';
    size: $size-7;
    weight: 500;
  }
  @include touch {
    font: {
      family: 'Raleway Regular';
      size: $size-7*0.8;
      weight: 400;
    }
  }
}

.rbc-row {
  .rbc-row-segment {
    padding-left: 1px;
    padding-right: 1px;
  }
  .rbc-row-segment:only-child {
    padding-left: 1px;
    padding-right: 1px;
  }
  .rbc-row-segment:first-child {
    padding-left: 1px;
  }
  .rbc-row-segment:last-child {
    padding-right: 1px;
  }
  // .rbc-row-segment:nth-child(2),
  .rbc-row-segment:empty,
  .rbc-row-segment:only-of-type {
    padding-left: 0;
    padding-right: 0;
  }
}

.rbc-today {
  background-color: rgba($cyan, .1);
}

.rbc-off-range-bg {
  background-color: rgba($cyan, .1);
}

.rbc-date-cell {
  text-align: left;
  padding: 6px 10px;
  margin: 0 auto;
  font: {
    family: 'Raleway Medium';
    size: $size-7;
    weight: 500;
  }
  .count-of-bookings {
    // text-transform: uppercase;
    color: $cyan !important;
    font: {
      family: 'Raleway Medium';
      size: $size-7;
      weight: 500;
    }
  }
}

.rbc-month-header {
  // background-color: rgba($cyan, .1);

}

.rbc-row-content .rbc-row:first-child {
  border-bottom: 1px solid #ddd;
}

.ReactModal__Content {
  opacity: 1;
}

.ReactModal__Content--after-open {
  opacity: 1;
  transition: opacity 500ms;
}

.ReactModal__Content--before-close {
  opacity: 0;
  transition: opacity 500ms;
}

#tab-content .kalender {
  display: none;
}

#tab-content .kalender.is-active {
  display: block;
}

// event-anlass

.rdp-chevron {
  fill: $cyan;
}

.rdp-selected {
  .rdp-day_button {
    background-color: $cyan;
    border: none;
  }
}

.rdp-range_start,
.rdp-range_end {
  .rdp-day_button {
    background-color: $cyan;
  }
}

.rdp-range_middle {
  background-color: #f0f8ff;
  .rdp-day_button {
    background-color: #f0f8ff;
  }
}