.rdp {
  --rdp-cell-size: 40px; /* Size of the day cells. */
  --rdp-caption-font-size: 18px; /* Font size for the caption labels. */
  --rdp-accent-color: #0000ff; /* Accent color for the background of selected days. */
  --rdp-background-color: #f0f8ff; /* Background color for the hovered/focused elements. */
  --rdp-accent-color-dark: #3003e1; /* Accent color for the background of selected days (to use in dark-mode). */
  --rdp-background-color-dark: #180270; /* Background color for the hovered/focused elements (to use in dark-mode). */
  --rdp-outline: 2px solid var(--rdp-accent-color); /* Outline border for focused elements */
  --rdp-outline-selected: 3px solid var(--rdp-accent-color); /* Outline border for focused _and_ selected elements */
  --rdp-selected-color: #fff; /* Color of selected day text */
}

.rdp-day.rdp-day_selected.rdp-day_range_middle {
  background-color: #f0f8ff !important;
  color: #19758c;
}
.rdp-day.rdp-day_selected.rdp-day_range_start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
  background-color: #19758c !important;
}
.rdp-day.rdp-day_selected.rdp-day_range_end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
    background-color: #19758c !important;
}
.rdp-day_today { 
  font-weight: bold;
  color: red;
}
