@use '../../../node_modules/bulma/sass/utilities/initial-variables.scss' as *;
@use '../../../node_modules/bulma/sass/utilities/mixins.scss' as *;

// -----------------
// Urls Header Images
// ------------------

$url_startbild_1: url('../images/start-harmonie-muenchen-1.jpg');
$url_startbild_2: url('../images/start-harmonie-muenchen-2.jpg');
$url_startbild_3: url('../images/start-harmonie-muenchen-3.jpg');


// ----------
// Start Page
// ----------

// NavBar and Background Image
// ---------------------------

.hero.is-fullheight {
	@include touch {
		min-height: auto;
	}
}

// Vereins Info Box
// ----------------
#vereins-info-box.container {
	// background-color: transparent;
	background-image: url('../images/hoehenlinien-light.png');
	background-repeat: repeat;
	background-size: cover;
	background-color: rgba($white,0.85);

	@include desktop {
		// position: absolute;
		// left: 0;
		// right: 0;
		top: -80vh;
    margin: 0 15vw;
    width: 70vw;
    max-width: 100%;
    padding: 0;
	}
	@include touch {
		top: 0;
		width: 100%;
	}
	> .box:first-child {
		background-color: transparent;
		padding: 4rem 2rem;
		.columns.is-ancestor {
			background-image: none;
			.box {
				background-color: transparent;
				background-image: none;
			}
			article {
				padding-top: 0.3rem;
				// @include widescreen {
				// 	padding-top: 7vw;
				// }
				@include tablet {
					width: 90%;
				}
				p {
					color: $grey-dark;
					font-size: $size-7;
				}
			}
			.column.align-vertical-center,
			.grid.align-vertical-center {
				display: flex;
				align-items: center;
			}
		}
	}
}


.vereins-name {
	@include tablet {
		margin-left: auto;
		margin-right: auto;
		width: 80%;
	}
	h1 {
		color: $cyan;
		// font-size: 3rem;
		font-family: 'Raleway Regular';
		font-size: 2.4vw;
		line-height: 1.3;
		margin: auto;
		text-align: left;
		span {
			font-size: 0.55em;
		}
		@include desktop-only {
			font-size: $size-3;
		}
		@include widescreen-only {
			font-size: $size-2;
		}
		@include touch {
			font-size: $size-2;
		}
	}

	img.image.vereins-wappen {
		background-color: transparent;
		margin: auto;
		margin-top: 2rem;
		width: 70%;
		@include touch {
			text-align: center;
		}
		@include desktop-only {
			width: 80%;
		}
	}
}

img.image.logo-muenchen {
	background-color: transparent;
	width: 25%;
	margin-top: 2rem;
	@include touch {
		text-align: center;
		width: 50%;
	}
	@include desktop-only {
		text-align: left;
	}
}

// Slideshow
@keyframes imageAnimation { 
	0% {
			opacity: 0;
			transform: scale(1.00);
	    animation-timing-function: ease-in-out;
	}
	16% {
	    opacity: 1;
	    transform: scale(1.05);
	}
	33% {
			opacity: 1;
			transform: scale(1.1);
			animation-timing-function: ease-in-out;
	}
	49% {
	    opacity: 0;
			transform: scale(1.05);
	}
	100% {
		opacity: 0;
		transform: scale(1.00);
	}
}

.full-screen-image,
.full-screen-image:after { 
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 0; 
}

ul.hero-body.full-screen-image:after {
	content: '';
	// background: transparent url(../images/pattern.png) repeat top left;
	background-color: rgba($grey-light, 0.3);
}
ul.hero-body.full-screen-image {
	li span { 
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0px;
			left: 0px;
			color: transparent;
			background-size: cover;
			background-attachment: fixed;
			background-position: 50% 50%;
			background-repeat: no-repeat;
			opacity: 0;
			z-index: 0;
			animation: imageAnimation 30s linear infinite 0s;	
	}
	li:nth-child(1) span {
		background-image: $url_startbild_1; 
	}
	li:nth-child(2) span { 
		background-image: $url_startbild_2;
		animation-delay: 10s; 
	}
	li:nth-child(3) span { 
		background-image: $url_startbild_3;
		animation-delay: 20s;
	}
}

// ------------------
// Modal Login Window
// ------------------

.modal-background {
  background-color: rgba($grey-dark, 0.2);
}
.modal-content {
	&.login {
		padding: 1rem;
		background-color: $white;
		> label {
			color: $grey;
		}
	}
	button.modal-close {
		z-index: 10;
	}
}

.modal-card-body,
.modal-card-head,
.modal-card-foot {
  border: solid 1px rgba($grey,0.5);
}

.modal-card-head {
  border-bottom: none;
}

.modal-card-foot {
  border-top: none;
	.button:not(:last-child) {
		margin-right: .5em;
	}
}