@use '../../../node_modules/bulma/sass/utilities/initial-variables.scss' as *;
@use '../../../node_modules/bulma/sass/utilities/mixins.scss' as *;

body {
  background-color: $grey;
  @include touch {
    background-color: $white;
  }
}
// -------
// Nav Bar
// -------

.navbar-dropdown {
	box-shadow: unset;
}


// ------------
// Image Upload
// ------------

.image-preview {
  margin-bottom: 20px;
}

.file-upload-preview[src=""] {
  visibility: hidden;
}

.image-preview {
  margin-top: 10px;
  display: inline-block;
}
