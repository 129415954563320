@use '../../../node_modules/bulma/sass/utilities/initial-variables.scss' as *;
@use '../../../node_modules/bulma/sass/utilities/mixins.scss' as *;

.navbar {
  padding-top: 4.5rem;
  // background-color: $cyan;
  @include touch {
    padding-top: 0rem;
    // background-color: $cyan;
  }
  .navbar-menu {
    background-color: transparent;
    @include touch {
      background-color: $cyan;
    }
  }

  .navbar-brand > .navbar-item,
  .navbar-end > .navbar-item {
    &:hover {
      background-color: transparent;
      color: $blue;
    }
    &.is-active {
      background-color: transparent;
      color: $blue;
    }
    @include touch {
      padding-left: 2.25rem;
      padding-right: 2.25rem;
    }
  }

  .navbar-item.has-dropdown {
    &.is-active {
      color: $blue;
      .navbar-dropdown {
        @include touch {
          display: block;
        }
      }
    }
    .navbar-item.navbar-link {
      background-color: transparent;
      @include touch {
        padding-left: 0;
        padding-right: 0;
      }
    }
    .navbar-item.navbar-link:hover {
      color: $blue;
      .navbar-dropdown {
        display: block;
      }
    }
    .navbar-dropdown {
      top: 75%;
      @include touch {
        top: 55%;
        display: none;
      }
      .navbar-item {
        background-color: transparent;
        color: $white;
        padding-left: 0.75rem;
        &.is-active {
          color: $blue;
        }
        &:hover {
          background-color: transparent;
          color: $blue;
        }
        @include touch {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }

  .navbar-brand {
    img {
      margin-top: -2.5rem;
      max-height: 3rem;
      @include touch {
        margin-top: -1rem;
        max-height: 2rem;
      }
    }
  }
}

#start-page .navbar {
  @include touch {
    background-color: $cyan;
  }
  .container {
    margin: 0 15vw;
    width: 70vw;
    max-width: 100%;
    padding: 0;
    @include touch {
      margin: 0;
      padding: 0;
      width: 100%;
    }
  }
}

#content-page .navbar {
  .container {
    padding: 0 5.75rem;
    @include touch {
      padding: 0;
    }
  }
}
