// Colors
@use '../../../node_modules/bulma/sass/utilities/initial-variables.scss' with (
  $grey-darker:   hsl(195, 71%, 19%),
  $grey-dark:     hsl(60, 1%, 34%),
  $grey:          hsl(0, 0%, 57%),
  $grey-light:    hsl(0, 0%, 71%),
  $grey-lighter:  hsl(0, 0%, 86%),
  $grey-lightest: hsl(221, 14%, 93%),

  $white-ter:    hsl(0, 0%, 96%),
  $white-bis:    hsl(0, 0%, 98%),
  $white:        hsl(0, 0%, 100%),

  $orange:       hsl(14,  100%, 53%),
  $yellow:       hsl(48,  100%, 67%),
  $green:        hsl(167, 64%, 35%),
  $turquoise:    hsl(171, 100%, 41%),
  $cyan:         hsl(192, 70%, 32%),
  $blue:         hsl(195, 71%, 19%),
  $purple:       hsl(271, 100%, 71%),
  $red:          hsl(0, 71%, 42%),
  
  // hsl(195, 71%, 19%)
  // Typography

  $family-sans-serif: 'Raleway Medium',
  // $button-family: $family-sans-serif,
  $family-monospace: monospace,


  $size-1: 3rem,
  $size-2: 2.5rem,
  $size-3: 2rem,
  $size-4: 1.625rem,
  $size-5: 1.5rem,
  $size-6: 1.25rem,
  $size-7: 1.0625rem,

  // Miscellaneous

  $easing: ease-out,
  $radius: none,
  $radius-small: none,
  $radius-medium: none,
  $radius-large: none,
  $radius-rounded: none,
  $speed: 86ms
);