@use '../../../node_modules/bulma/sass/utilities/initial-variables.scss' as *;
@use '../../../node_modules/bulma/sass/utilities/mixins.scss' as *;

// -----------------
// Urls Header Images
// ------------------

$header_aktuelles: url('../images/header-harmonie-muenchen-aktuelles.jpg');
$header_termine: url('../images/header-harmonie-muenchen-termine.jpg');
$header_hans_klessing_huette: url('../images/header-harmonie-muenchen-hans-klessing-huette.jpg');
$header_latschenkopf_huette: url('../images/header-harmonie-muenchen-latschenkopf-huette.jpg');
$header_galerie: url('../images/header-harmonie-muenchen-galerie.jpg');
$header_touren: url('../images/header-harmonie-muenchen-touren.jpg');
$header_mitglieder: url('../images/header-harmonie-muenchen-mitglieder.jpg');
$header_kontakt: url('../images/header-harmonie-muenchen-kontakt.jpg');
$header_impressum: url('../images/header-harmonie-muenchen-impressum.jpg');

// -------------
// Content Pages
// -------------

.hero.is-standard {
	z-index: 10;
	&.is-sticky {
		position: -webkit-sticky;
		position: sticky;
		top: 0;
	}
	// .hero-head {

	// }
	.hero-body {
		background-image: $header_aktuelles;
		// background-image: none;
		background-position: center top;
		background-size: cover;
		background-repeat: no-repeat;
		height: 25vw;
		position: relative;
		min-height: 50px;
		@include touch {
			height: 150px;
			// width: 100%;
			// min-height: 50px;
		}
		&:after { 
			content: '';
			position: absolute;
			width: 100%;
			height: 300px;
			top: 0px;
			left: 0px;
			@include touch {
				content: '';
				background: transparent;
			}
		}
		&.aktuelles:after,
		&.latschenkopf_huette:after {
			$gradient-color: $cyan;
			background: linear-gradient(
				rgba($gradient-color, 0.4),
				rgba($gradient-color, 0) 80%,
				rgba($gradient-color, 0) 100%);
		}
		&.latschenkopf_huette.transparent-after:after {
			position: fixed;
			width: 100%;
			height: 152px;
			top: 0px;
			left: 0px;
			background: rgba($cyan,0.3);
		}
		&.termine:after {
			$gradient-color: $grey;
			background: linear-gradient(
				rgba($gradient-color, 1.0),
				rgba($gradient-color, 0) 80%,
				rgba($gradient-color, 0) 100%);
		}
		&.termine.transparent-after:after {
			position: fixed;
			width: 100%;
			height: 152px;
			top: 0px;
			left: 0px;
			background: rgba($grey,0.3);
		}
		&.transparent-after:after {
			background: transparent;
		}
		.container {
			position: absolute;
			bottom: 5rem;
			margin-left: auto; 
			margin-right: auto;
			left: 0;
			right: 0; 
			padding: 0 5.75rem;
			@include widescreen-only {
				bottom: 3.5rem;
			}
			@include desktop-only {
				bottom: 2.5rem;
			}
			@include touch {
				bottom: 1.5rem;
				padding: 0 2.25rem;
			}

		}
		&.aktuelles {
			background-image: $header_aktuelles;
		}
		&.termine {
			background-image: $header_termine;
			h1.hero-title {
				color: $cyan;
			}
		}
		&.hans_klessing_huette {
			background-image: $header_hans_klessing_huette;
		}
		&.latschenkopf_huette {
			background-image: $header_latschenkopf_huette;
			h1.hero-title {
				color: $cyan;
			}
		}
		&.galerie {
			background-image: $header_galerie;
		}
		&.touren {
			background-image: $header_touren;
		}
		&.mitglieder {
			background-image: $header_mitglieder;
		}
		&.kontakt {
			background-image: $header_kontakt;
		}
		&.impressum {
			background-image: $header_impressum;
		}
	}
}

.section {
	padding: {
		top: 12px;
		bottom: 12px;
	}
	@include desktop {
		background-image: url('../images/hoehenlinien_x4.png');
		background-repeat: repeat;
		background-position: left top;
		background-size: auto;
		// background-attachment: fixed;
		background-color: rgba($white, 0.85);
	}
	.container {
		background-color: $white;
		// padding-bottom: 5rem;
		.columns:first-child {
			padding-top: 5rem;
		}
		.columns:last-child {
			padding-bottom: 5rem;
		}
		.columns {
			background-color: $white;
			// margin-left: 0;
			// margin-right: 0;
			@include desktop {
				padding: 0 5rem;
				&.is-gallery {
					padding: 0 5.75rem;
				}
			}
		}
	}
}

// ------
// Footer
// ------

footer.footer {
	padding: 0;
	.section {
		background-color: $cyan;
		background-image: none;
		.container {
			background-color: transparent;
			.columns {
				background-color: transparent;
				@include touch {
					padding-top: 0;
				}
				.column {
					background-color: transparent;
				}
				.column:last-child {
					@include desktop {
						padding-left: 65px;
						padding-right: 0px;
					}
				}
			}
		}
	}
}

.card-content .content {
	font-size: 14px;
	margin: 0rem;
	h4 {
		font-size: 16px;
		font-weight: 700;
	}
}

// .card {
// 	margin: 0 !important;
// }

// .termine:first-child {
// 	border: none;
// 	margin-top: 0rem !important;
// }

article.aktuell.media,
article.termin.media,
article.tour.media,
article.galerie {
	border: none;
	margin-bottom: 3rem !important;
}

article.aktuell,
article.termin,
article.tour,
article.galerie {
	.media-content {
		// .column:first-child {
		// 	padding-left: 0;
		// }
		// .column:last-child {
		// 	padding-right: 0;
		// }
	}
}

// ----------------------
// Button Aktuell/Archive
// ----------------------
.button_to {
	display: inline;
}

.archive-button {
	background: transparent;
	border: 0;
	display: inline;
	.fa-eye-slash {
		color: $red;
		opacity: 0.5;
		&:hover {
			opacity: 1.0;
		}
	}

}

.aktuell-button,
.visability-button {
	background: transparent;
	border: 0;
	display: inline;
	.fa-eye {
		color: $green;
		opacity: 0.5;
		&:hover {
			opacity: 1.0;
		}
	}

}

.edit-button,
.delete-button {
	background: transparent;
	border: 0;
	display: inline;
	.fa-edit,
	.fa-trash {
		opacity: 0.5;
		&:hover {
			opacity: 1.0;
		}
	}
	.fa-trash {
		color: $red;
	}

}


turbo-frame {
	width: 100% !important;
}

// ------------
// Flash Notice
// ------------
// .flash {
// 	// transform: translateX(-50%);
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   gap: var(--space-s);

//   max-width: 100%;
//   width: max-content;
//   padding: 0 var(--space-m);

//   &__message {
//     font-size: var(--font-size-s);
//     padding: var(--space-xs) var(--space-m);
//     background-color: var(--color-dark);
//     animation: appear-then-fade 4s both;
//     border-radius: 999px;
//   }
// }

@keyframes appear-then-fade {
  0%, 100% {
    opacity:0
  }
  5%, 60% {
    opacity:1
  }
}
