@use '../../../node_modules/bulma/sass/utilities/initial-variables.scss' as *;
@use '../../../node_modules/bulma/sass/utilities/mixins.scss' as *;

a.navbar-item,
.navbar-link {
  // color: $white;
  font: {
    family: 'Raleway Bold';
    size: $size-7*0.8;
  }
}

h1.hero-title {
  color: $white;
  text-transform: uppercase;
  line-height: 1.2;
  font: {
    family: 'Raleway Regular';
    size: $size-1;
  }
  @include until-fullhd {
    font-size: $size-3;
  }
  @include desktop-only {
    font-size: $size-4;
  }
  @include tablet-only {
    font-size: $size-5;
  }
  @include mobile {
    font-size: $size-6;
  }
}

// Kategorien Überschrift
h1.title {
  line-height: 1.2;
  color: $grey-dark;
  text-transform: uppercase;
  font: {
    family: 'Raleway Medium';
    size: $size-4;
    weight: 600;
  }
} 

// Artikel Überschrift
h2.title {
  line-height: 1.2;
  color: $cyan;
  text-transform: uppercase;
  font: {
    family: 'Raleway Medium';
    size: $size-7;
    weight: 600;
  }
} 

// Termin Datum
h5.title {
  color: $cyan;
  line-height: 1.2;
  margin-bottom: 9px !important;
  font: {
    family: 'Raleway SemiBold';
    size: $size-7;
    weight: 700;
  }
}

h3.image-gallery-title {
  color: $grey-dark !important;
  line-height: 1.5;
  font: {
    family: 'Raleway Medium';
    size: $size-7;
    weight: 500;
  }
}

// ------------------------
// Paragraph Text and Links
// ------------------------

p {
  margin-bottom: 1em;
  font: {
    family: 'Raleway Medium';
    size: $size-7;
    weight: 500;
  }
}

a,
span a {
  color: $cyan;
  font: {
    family: 'Raleway Medium';
    size: $size-7;
    weight: 500;
  }
}

.label {
  color: $cyan;
  font: {
    family: 'Raleway Medium';
    size: $size-7;
    weight: 500;
  }	
}

.button,
.input,
.textarea,
.select select {
  font: {
    family: 'Raleway Medium';
    size: $size-7;
    weight: 500;
  }	
}
