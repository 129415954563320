@use '../../../node_modules/bulma/sass/utilities/initial-variables.scss' as *;
@use '../../../node_modules/bulma/sass/utilities/mixins.scss' as *;
// -------
// Masonry
// -------

.grid-sizer {
	width: 25%;
	margin: 0;
}

.gutter {
	width: 0;
	margin: 0;
}

.grid-item {
	float: left;
	width: 25%;
	margin-bottom: 0;
	img {
		width: 100%;
	}
}

// ------------------
// Gallery Index Page
// ------------------

.container {
	.columns.is-gallery {
		margin-bottom: 0 !important;
		@include tablet {
			.column {
				max-width: 25%;
			}
		}
	}
	.is-gallery:nth-child(2) {
		padding-top: 2rem !important;
	}
	.is-gallery:last-child {
		padding-bottom: 5.75rem !important;
		margin-bottom: -12px !important;
	}
}


div.columns

// ----------------
// Gallery Overlays
// ----------------

.image-gallery {
	position: relative;
	overflow: hidden;
	.image-gallery-overlay {
		background: rgba($white, 0.8);
		position: absolute;
		height: 90%;
		width: 90%;
		left: 5%;
		top: 5%;
		bottom: 0;
		right: 0;
		opacity: 0;
		z-index: 3;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: all 0.4s ease-in-out 0s;
	}
	&:hover,
	&:focus {
		.image-gallery-overlay {
			opacity: 1;
		}
		.image-gallery-details {
			opacity: 1;
		}
	}
	.image-gallery-image {
		object-fit: cover;
	}
}

.image-gallery-details {
	position: absolute;
	text-align: left;
	margin-left: 1rem;
	padding-left: 1em;
	padding-right: 1em;
	width: 100%;
	top: 72%;
	left: 0;
	opacity: 0;
	//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
	// transform: translate(-50%, -50%);
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: all 0.3s ease-in-out 0s;
	z-index: 4;
	h3 {
		color: $grey;
		text-transform: uppercase;
	}
	p {
		color: $grey-dark;
		font-size: $size-7;
	}
}

// ------------
// Slick Slider
// ------------

.slick-slider {
	// position: relative;
	// display: block;
	// box-sizing: border-box;
	width: 1184px;
	@include widescreen-only {
		width: 992px;
	}
	@include desktop-only {
		width: 800px;
	}
	@include touch {
		width: 95vw;
	}
}

.slick-slide {
  margin: 0 0.5rem;
  img {
    max-height: 240px;
  }
}

.slick-prev,
.slick-next {
    background: $white;
    color: $cyan;
    top: 50%;
    &:hover, &:focus {
        outline: none;
        background: transparent;
        color: transparent;
        &:before {
            opacity: 0.5;
        }
    }
    &.slick-disabled:before {
        opacity: 1;
    }
    &:before {
        // font-family: $slick-font-family;
        font-size: 20px;
        line-height: 1;
        color: $cyan;
        opacity: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}